.footer {
  width: 100%;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  text-align: center;
  font-size: 12px;
}

.footer-left {
  position: absolute;
  left: 20px;
  text-align: left;
}

.footer-left .footer-left__link {
  color: white;
}

.footer-center {
  display: inline-block;
}

.footer-center p {
  display: inline;
}
