.mascote-fibra-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    margin-top: 20px;
}

.mascote-fibra-image {
    position: fixed;
    z-index: 1;
    right: -55px;
    overflow: hidden;
    height: 155px;
    bottom: 0;
}

.mascote-fibra-image img {
    width: 300px;
}

.mascote-fibra-balao {
    background-color: white;
    border-radius: 10px;
    padding: 0 20px;
    position: fixed;
    right: 11%;
    bottom: 1%;
    z-index: 2;
    min-width:200px;
    min-height: 100px;
    max-width: 200px; /* Tamanho original do balão */
    max-height: 100px;
    overflow-y: auto; /* Habilita o scroll vertical quando necessário */
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.mascote-fibra-container:after {
    content: '';
    position: fixed;
    border-style: solid;
    border-width: 15px 2.87rem 0 10px;
    border-color: white transparent transparent transparent;
    bottom: 3%;
    right: 9%;
    transform: rotate(-10deg);
    z-index: 2;
    filter: drop-shadow(0.7rem 0px 0px black);
}

.mascote-fibra-balao,
.mascote-fibra-image {
    filter: drop-shadow(5px 5px 0px black);
}

.mascote-fibra-balao.expandido {
    max-width: 85vw;
    max-height: calc(100vh - 85px); /* Altura da tela menos a altura da navbar */
    overflow: hidden;
}

.mascote-fibra-balao ul {
    list-style-type: none;
    padding: 0;
}

.mascote-fibra-balao li {
    white-space: nowrap;
}

.mascote-fibra-balao li,
.mascote-fibra-balao p {
    color: #000;
    font-size: 1rem;
    margin: 0;
    text-align: left;
}

.mascote-fibra-balao p {
    position: absolute; /* Faz o parágrafo ficar posicionado dentro do contêiner */
    top: 50%; /* Move 50% para baixo */
    left: 50%; /* Move 50% para a direita */
    transform: translate(-50%, -50%);
    width: 80%;
    text-align: center;
}

.copy-icon {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
    color: darkslategray;
}

.copy-icon:hover {
    color: #007bff;
}

@media (max-width: 1367px) {
    .mascote-fibra-balao {
        right: 14%;
    }

    .mascote-fibra-container:after {
        border-width: 20px 2.3rem 0 10px;
        bottom: 4%;
        right: 12%;
    }
}
