.pagina-texto__emoji p,
.pagina-texto__conteudo p {
    margin: 20px 0;
    text-shadow: 4px 4px black;
}

.pagina-texto__conteudo p {
    margin-bottom: 50px;
}

.pagina-texto__emoji,
.pagina-texto__conteudo {
    margin: 10px 20px;
    text-align: left;
}

.pagina-texto__emoji {
    font-size: 14rem;
}

.pagina-texto__conteudo {
    font-size: 4rem;
}

@media (max-width: 1367px) {
    .pagina-texto__emoji {
        font-size: 11rem;
    }

    .pagina-texto__conteudo {
        font-size: 3rem;
    }
}
