body {
    font-family: 'Roboto', sans-serif;
    background-color: #1e1e1e;
    color: #fff;
    text-align: center;
}

/* header */

.pagina-header {
    position: relative;
    height: 70px;
    background-image:
        url('../../public/images/header-background.png');
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Garante que nada saia do contêiner */
    margin-bottom: 20px;
}

/* Conteúdo principal da página */
.page-content {
    padding-top: 70px;
}

/* Ajuste a imagem transparente */
.transparent-image {
    position: absolute;
    max-width: 38%;
    top: -15px;
    left: 50%;
    transform: translateX(-50%); /* Corrige o alinhamento central */
    z-index: 2;
}

/* Card */

.card {
    margin: 20px auto;
    margin-top: 0;
    padding: 0 20px;
    border-radius: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card h2 {
    font-size: 48px;
    margin: 20px auto;
}

.card p {
    margin: 0;
}

.buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    min-width: 800px;
}

.fluxo-button {
    flex: 1 1 250px;
    max-width: 210px;
    height: 390px;
    color: white;
    border: none;
    padding: 15px 6px;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    font-size: 1.5rem;
    justify-content: center;
    filter: drop-shadow(5px 5px 0px black);
    background-color: transparent;
}

/* Estilo para redimensionar todos os botões quando há mais de 5 */
.buttons-container.more-than-five .fluxo-button {
    flex: 1 1 135px;
    max-width: 135px;
    height: 220px;
    font-size: 1rem;
}

.fluxo-button:active {
    transform: translate(5px, 5px);
    filter: none;
}

.video-background, .image-background {
    position: relative;
    width: 40%;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 10px;
    padding: 20px;
    background-color: #125475;
    border-radius: 10px;
}

.video-content {
    max-width: 100%;
    height: auto;
}

.video-caption, .image-caption {
    margin-top: 10px;
    width: 100%;
    color: white;
    font-size: 20px;
    text-align: center;
}

.tip {
    background-color: #4CAF50;
    color: white;
    padding: 20px;
    margin: 20px auto;
    text-align: center;
    font-size: 18px;
    width: 40%;
    border-radius: 10px;
}

.italic {
    font-style: italic;
}

.card-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 800px; /* Optional: limit max width for better layout */
    width: 100%;
    margin: 10px auto;
}


/* transição entre páginas */
.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in-out;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-out;
}

/* Estilo para o layout de mídia */

.pagina-midia-layout {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 20px;
    padding-top: 0px;
    flex-wrap: nowrap; /* Garante que os itens não se quebrem em uma nova linha */
    overflow: hidden;
    margin-top: 20px;
}

.midia-container {
    flex: 2 1;
    padding: 10px;
    background-color: #a0543e;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    flex-shrink: 0; /* Evita que o container de mídia encolha */
    max-width: 65%;
}

.midia-container,
.pagina-procedimento,
.pagina-dica {
    filter: drop-shadow(5px 5px 0px black);
}

.midia-image, .midia-video {
    border-radius: 10px;
    overflow: hidden;
}

.midia-image img,
.midia-video video {
    width: 100%;
    border-radius: 10px;
}

.midia-legenda {
    text-align: center;
    background-color: #a0543e;
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
}

.midia-legenda p {
    display: inline;
}

.midia-card {
    display: flex; /* Usa flexbox para organizar o layout */
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 15px;
    flex-direction: column;
}

.midia-card h2 {
    font-size: 16px;
    margin: 0;
    font-weight: initial;
}

.midia-buttons-container {
    display: flex;
    flex-wrap: wrap; /* Permite que os botões quebrem em novas linhas */
    gap: 10px; /* Espaçamento entre os botões */
    justify-content: center; /* Centraliza as colunas */
    align-items: flex-start;
    min-width: 100%;
}

.midia-buttons-container .fluxo-button {
    flex: 0 1 36%;
    max-width: 32%;
    min-width: 120px;
    margin-bottom: 2px;
    max-height: fit-content;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 77px;
}

.informacoes-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    flex-shrink: 1; /* Permite que o container de informações se ajuste */
    max-width: 34%;
}

/* Outros estilos */
.pagina-procedimento,
.pagina-dica,
.midia-card h2,
.midia-card__titulo h2 {
    overflow-y: overlay;
}

.pagina-procedimento {
    max-height: 300px;
}

.pagina-dica {
    max-height: 130px;
}

.procedimento-texto p,
.pagina-dica p {
    margin: 5px auto;
}

.midia-card__titulo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 64px;
}

.midia-card__titulo h2 {
    max-height: 70px;
}

.pagina-procedimento,
.pagina-dica,
.midia-card,
.midia-buttons-container button {
    max-width: 100%;
    box-sizing: border-box;
}

.pagina-procedimento,
.pagina-dica,
.midia-legenda,
.midia-card,
.card.midia-card h2,
.fluxo-button {
    text-shadow: 2px 2px black;
    font-size: 21px;
}

.pagina-procedimento {
    padding: 0 10px;
    background-color: #99003B;
    border-radius: 10px;
}

.pagina-card-container {
    padding: 0 0 12px 0px;
    background-color: #007bff;
    border-radius: 10px;
    max-height: 55px;
    position: relative;
    box-shadow: 5px 5px 0px black;
}

.no-scroll {
    overflow-y: hidden;
}

.page-content a {
    text-decoration: none;
    color: #00a6f6;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: #777777; /* Cor da "thumb" */
    border-radius: 6px; /* Arredondamento dos cantos */
}

/* Estilo para o "track" da barra de rolagem (a trilha de fundo) */
::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Cor do fundo da barra */
    border-radius: 6px;
}

/* Configurações para conteúdo de mídia de monitores médios para baixo */
@media (max-width: 1367px) {
    .page-content {
        padding-top: 30px;
    }

    .pagina-header {
        height: 40px;
    }

    .fluxo-button {
        font-size: 22px;
        text-shadow: 2px 2px #000;
    }

    .pagina-dica {
        max-height: 90px;
    }

    .pagina-procedimento,
    .pagina-dica,
    .midia-legenda,
    .midia-card,
    .card h2,
    .midia-card h2 p,
    .midia-buttons-container .fluxo-button {
        font-size: 17px;
        text-shadow: 1px 1px black;
    }

    .midia-buttons-container .fluxo-button {
        padding: 5px;
    }

    .pagina-card-container {
        max-height: 40px;
    }

    .midia-card__titulo {
        height: 48px;
    }

    .card h2 {
        font-size: 36px;
    }

    .pagina-procedimento {
        max-height: 120px;
    }

    .informacoes-container {
        max-width: 41%;
    }

    .pagina-midia-layout {
        justify-content: unset;
    }

    .midia-container {
        max-width: 58%;
    }
}
