.pagina-dica {
    padding: 0 10px;
    background-color: #a74ac7;
    border-radius: 5px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    background-color: red;
    cursor: pointer;
}

@keyframes blinker {
    50% {
        background-color: blue;
    }
}
