/* Navbar base styling */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    width: 100%;
    z-index: 1000;
    height: 70px;
    background-image:
        url('../../public/images/header-background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden; /* Garante que nada saia do contêiner */
    position: fixed;
    top: 0;
  }

  /* Left section of the navbar */
  .navbar-left {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 20px;
  }

  /* Center section of the navbar */
  .navbar-center {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1; /* Ensures it takes the available space between left and right */
    gap: 15px;
  }

  /* Right section of the navbar */
  .navbar-right {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-right: 20px;
  }

  /* Navigation icons styling */
  .nav-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .nav-icon img {
    height: 40px;
  }

  /* Page title styling */
  .page-title {
    font-size: 24px;
    font-weight: bold;
    width: 30%;
    text-shadow: 2px 2px black;
  }

  /* Logo styling */
  .logo {
    max-width: 40%;
    transform: translate(-30px, 1px);
    height: 60px;
  }

  .disabled {
    opacity: 0.5;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .navbar {
      padding: 8px 15px;
    }

    .page-title {
      font-size: 18px;
    }

    .logo {
      height: 30px;
    }
  }

  @media (max-width: 1367px) {
    .navbar {
      height: 40px;
    }

    .nav-icon img {
        height: 28px;
    }

    .page-title {
      font-size: 18px;
      width: 32%;
    }
  }
