.simple-not-found-page {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.simple-not-found-page canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.not-found-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 64px;
  font-weight: bold;
  z-index: 1;
  text-align: center;
}

.not-found-subtext {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  font-weight: bold;
  z-index: 1;
  text-align: center;
  width: 100%;
}
